<netz-page-heading size="xl">Tasks</netz-page-heading>

<govuk-tabs>
  @if (role() !== 'OPERATOR') {
    <ng-template govukTabLazy id="assigned-to-me" label="Assigned to me">
      <ng-container
        *ngTemplateOutlet="
          tabTemplate;
          context: { unassigned: 'Regulator', tab: 'assigned-to-me', header: 'Assigned to me' }
        "
      ></ng-container>
    </ng-template>
  }

  <ng-template govukTabLazy id="assigned-to-others" label="Assigned to others">
    <ng-container
      *ngTemplateOutlet="tabTemplate; context: { tab: 'assigned-to-others', header: 'Assigned to others' }"
    ></ng-container>
  </ng-template>

  <ng-template govukTabLazy id="unassigned" label="Unassigned">
    <ng-container
      *ngTemplateOutlet="tabTemplate; context: { unassigned: 'Unassigned', tab: 'unassigned', header: 'Unassigned' }"
    ></ng-container>
  </ng-template>
</govuk-tabs>

<ng-template #tabTemplate let-unassigned="unassigned" let-tab="tab" let-header="header">
  <h2 class="govuk-heading-m">{{ header }}</h2>

  @if (activeTab() === tab && !isLoading()) {
    <div>
      <cca-dashboard-items-list
        [items]="items()"
        [tableColumns]="tableColumns()"
        [unassignedLabel]="unassigned"
      ></cca-dashboard-items-list>
    </div>
  }

  @if (total() > pageSize) {
    @if (activeTab() === tab && !isLoading()) {
      <cca-pagination (currentPageChange)="changePage($event)" [count]="total()" [pageSize]="pageSize"></cca-pagination>
    }
  }
</ng-template>
