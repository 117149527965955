<netz-page-heading size="xl">Terms and Conditions and Administrative Rules</netz-page-heading>

@if (terms(); as terms) {
  <div class="govuk-body">
    <p>
      Below are the Terms and Conditions for the Climate Change Agreements Website and the Administrative Rules for the
      Climate Change Agreements Register.
    </p>
    <h2 class="govuk-heading-m">Climate Change Agreements Website Terms and Conditions</h2>

    <govuk-accordion [openIndexes]="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]">
      <govuk-accordion-item [header]="'1. Status of Terms and Conditions'">
        <p>
          1.1 This Department for Energy Security and Net Zero (DESNZ) Climate Change Agreements website for the UK
          ("<strong>Website</strong>") () is established in connection with the Climate Change Agreements
          (Administration) Regulations 2012 (as amended from time to time) ("<strong>Administration Regulations</strong
          >") and Schedule 6 to the Finance Act 2000 ("<strong>Finance Act</strong>") in the UK. The operation of the
          scheme is also subject to the Climate Change Agreements (Eligible Facilities) Regulations 2012 (as amended
          from time to time) ("<strong>Eligibility Regulations</strong>", together with the Finance Act and the
          Administration Regulations, the "<strong>CCA Legislation</strong>").
        </p>
        <p>
          1.2 The Environment Agency ("<strong>We</strong>", "<strong>Us</strong>", "<strong>Our</strong>") acts as
          administrator ("<strong>Administrator</strong>") of the electronic Register We established under the
          Administration Regulations ("<strong>Register</strong>"). The Website is a portal to the Register, and its web
          pages are accessible to the public. The Register itself is secure and accessible only by Account Holders and
          their authorised representatives ("<strong>Representatives</strong>"). As part of Our role as Administrator,
          We also manage this Website.
        </p>
        <p>
          1.3 "<strong>You</strong>" and "<strong>Your</strong>" includes, where You are a Sector Association or
          Operator, Your Representatives.
        </p>
        <p>
          1.4 These Website terms and conditions ("<strong>Terms and Conditions</strong>") govern Your access to and use
          of the Website and are a contract between You and Us. They do not apply to the Register, to which only those
          We have granted security protected access rights have access.
        </p>
        <p>
          1.5 To be granted access rights to the Register, You need to obtain a user name, password and PIN from Us to
          use with Your own email address (together "<strong>Credentials</strong>") and complete the relevant enrolment
          procedures on the Register. Access to and use of the Register (and accounts within it) is subject to a
          separate governance document (the "<strong>Administrative Rules</strong>"). The latest version of the
          Administrative Rules and these Terms and Conditions may be viewed at:&#xa0;<a
            href="https://www.gov.uk/guidance/climate-change-agreements--2"
            class="govuk-link"
            rel="noreferrer noopener"
          >
            https://www.gov.uk/guidance/climate-change-agreements--2
          </a>
        </p>
        <p>
          1.6 Unless defined in these Terms and Conditions or the context requires otherwise, capitalised terms shall
          have the same meaning as defined in Part 4 of the Administrative Rules.
        </p>
        <p>
          1.7 If you have been issued with Credentials and wish to complete enrolment into the Register (where
          applicable) You are required to accept these Terms and Conditions, and You will do this at the same time that
          You acknowledge having read and understood the Administrative Rules. You will be required to accept these
          Terms and Conditions and acknowledge the Administrative Rules the first time You log in to the Register. If
          there is an update to those documents then You will be required again to accept the updated Terms and
          Conditions and acknowledge the updated Administrative Rules the next time You log in to the Register.
        </p>
        <p>
          1.8 By continuing to use this Website, You are accepting the latest version of these Terms and Conditions in
          full. We may revise these Terms and Conditions at any time so You should check the Website regularly to ensure
          that You are aware of the latest version of these Terms and Conditions.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'2. Use of this Website'">
        <p>
          2.1 We grant You the right to view the data, text, images, software, executable code, audio, video and other
          similar material available on or through the Website ("<strong>Content</strong>"). You agree You will not:
        </p>
        <p>
          (a) alter or remove any copyright, trade mark or other proprietary mark, logo or notice of the Administrator
          or of any other company, organisation or public authority appearing anywhere on the Website;
        </p>
        <p>
          (b) modify or edit or publish or sell the Content including but not limited to making the Content available on
          any other website;
        </p>
        <p>
          (c) to the maximum extent permitted by law, reverse engineer, translate, adapt or modify any software used in
          connection with any part of the Website;
        </p>
        <p>
          (d) attempt to damage or corrupt (including through the introduction of any virus, trojans, worm, logic bomb
          or other material which is malicious or technologically harmful) any software or data used in connection with
          any part of the Website, nor, attempt to gain unauthorised access to any part of the Register, the server on
          which the Website is stored or any server, computer or database connected to the Website; or
        </p>
        <p>(e) create any links from any other website to the Website without Our express prior written permission.</p>
        <p>2.2 You agree to use the Website only for lawful purposes, and in a manner that does not:</p>
        <p>(a)&#xa0; infringe the rights of the Crown, Our rights or the rights of any third party;</p>
        <p>(b) restrict or inhibit the use and enjoyment of the Website by any third party; or</p>
        <p>(c) restrict or inhibit Our operation of the Website.</p>
        <p>
          2.3 We may, at Our sole discretion, terminate Your access to or use of the Website for any reason, including
          without limitation where it is Our belief that You have not acted in accordance with these Terms and
          Conditions.
        </p>
        <p>
          2.4 The Website and its Content are subject to change without notice at any time. We reserve the right, at Our
          sole discretion, to make any alteration, correction or improvement to, or to withdraw or correct any error or
          omission in, any portion of the Content and/or the Website without notice.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'3. Third party sites and hyperlinking'">
        <p>
          3.1 We may link the Website to other websites, which are not under Our control or maintenance. We are
          providing these links to You only as a matter of convenience. To the maximum extent permitted by law, We
          accept no responsibility for the content, availability or reliability of such websites. We do not endorse or
          recommend any products, materials or services displayed or offered on any websites which may be linked to the
          Website.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'4. Statements'">
        <p>
          4.1 In this clause 4&#xa0;<strong>Third Party Information</strong>&#xa0;means all Content of a party that is
          an Account Holder or a Representative of an Account Holder, including:
        </p>
        <p>(a) documents and information submitted to the Register by or on behalf of an Account Holder; and</p>
        <p>(b) information or documents provided by, or relating to, such a party:</p>
        <p>(i) We use to generate additional Content; or</p>
        <p>(ii) We keep and maintain as Content;</p>
        <p>
          under the CCA Legislation. In each case Third Party Information shall include, but not be limited to, the
          following, which We are required under the Administration Regulations (subject to certain exceptions as to
          security) to publish on the Website:
        </p>
        <p>
          (i) the list of names and addresses of Facilities which are to be taken as being covered by an Agreement for
          the appropriate period, as provided for in Paragraph 44 of Schedule 6 to the Finance Act;
        </p>
        <p>
          (ii) the list of Facilities that are the subject of a reduced rate certificate as provided for in Paragraph 44
          of Schedule 6 to the Finance Act and in respect of which variation certificates have been given since the last
          publication of the list; and
        </p>
        <p>
          (iii) the report We prepare, which sets out energy efficiency improvements and emission reductions achieved
          under the Target Period;
        </p>
        <p>
          but excluding such Content provided by, or relating to, such a party as We publish or otherwise make available
          on the Website which Content We are under a duty to verify under the CCA Legislation.
        </p>
        <p>
          4.2 To the extent permissible having regard to Our obligations, duties and responsibilities under the CCA
          Legislation, and to the extent permitted by Law, nothing in these Terms and Conditions will be construed as a
          confirmation from Us to You:
        </p>
        <p>(a) as to the accuracy of any Third Party Information contained in, or accessed from, the Website;</p>
        <p>(b) as to the reliability of the Website;</p>
        <p>(c) that the Website or its Content will be free from errors;</p>
        <p>
          (d) that the Website or its Content and the related servers are free from computer viruses or other harmful
          applications; or
        </p>
        <p>
          (e) as to the satisfactory quality, merchantability and fitness for purpose (including the correct operation
          of the Website with your computer hardware and software configuration) of the Website and its Content.
        </p>
        <p>
          4.3 In relation to all Content that is not regulatory guidance intended to assist You in understanding the
          scheme and Your responsibilities and compliance obligations in relation thereto, any reliance You place on any
          Content of the Website is at Your own discretion and risk and it is solely Your responsibility to ensure that
          such Content meets Your requirements.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'5. Liability'">
        <p>
          5.1 To the full extent permitted by Law, We accept no liability for any claims, loss, demands or damages of
          any kind with respect to the Website or its Content (or which You may suffer or incur in connection with the
          Website or its Content) including, without limitation, direct, indirect, or incidental loss or damages, loss
          of profits, loss of revenue, loss of data, loss of use or otherwise arising out of Your use of the Website and
          whether or not the possibility of such loss has been notified to Us, including:
        </p>
        <p>
          (a) as a result of the introduction to the Website of any viruses, trojans, worms, logic bombs or other
          material which is malicious or technologically harmful;
        </p>
        <p>
          (b) by any attempt to gain unauthorised access to the Register, the server on which the Website is stored or
          any server, computer or database connected to the Website;
        </p>
        <p>
          (c) by any attack to the Website via a denial-of-service attack or a distributed denial-of-service attack;
        </p>
        <p>
          (d) any matter over which We have no control, including, but not limited to, the acts of third parties or
          unplanned outages;
        </p>
        <p>(e) Your failure or that of Your Representatives to comply with these Terms and Conditions; or</p>
        <p>
          (f) the content of any material posted to the Website by You or third parties which is defamatory, obscene,
          inaccurate, misleading, unlawful, pornographic or which in any way infringes a third party's rights, including
          but not limited to, intellectual property rights.
        </p>
        <p>5.2 Clause 5.1 will apply howsoever to such claims, loss or damages that might arise.</p>
        <p>
          5.3 If You enter the Website, You agree that clause 4, relating to statements, and this clause 5, relating to
          limitation of liability, are reasonable and reflect the nature of the Website.
        </p>
        <p>
          5.4 Nothing in these Terms and Conditions will exclude or limit Our liability for fraud, death or personal
          injury caused by negligence or any liability which cannot be excluded or limited under Law.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'6. Intellectual Property Rights'">
        <p>
          6.1 You agree that all intellectual property rights in the data, text, images, materials and information
          ("<strong>Intellectual Property Rights</strong>") in the Content and Website (including in the layout of the
          Website) are owned by Us (or Our licensors) or are subject to Crown Copyright. You will not alter or remove
          any copyright, trade mark or other proprietary mark, logo or notice of Ours or of any other organisation or
          public authority appearing anywhere on the Website.
        </p>
        <p>
          6.2 We grant You the right to view, download or print the publicly accessible Content for Your own private use
          or for use internally within Your business subject to the following conditions:
        </p>
        <p>(a) You may give copies of the Content to others provided that:</p>
        <p>(i) You make no charge;</p>
        <p>(ii) You in no way modify or edit the Content; and</p>
        <p>
          (iii) You attach a copy of this Clause and obtain a legally binding undertaking from the recipient to comply
          with it; and
        </p>
        <p>
          (b) any authorised copying acknowledges Our ownership of the Content or its status as being subject to Crown
          Copyright, unless otherwise agreed by Us. One way of doing this is by adding the words "Copyright Environment
          Agency or Crown Copyright" to the information or copy.
        </p>
        <p>
          6.3 You agree that You will not use the Content in any way other than as set out in clause 6.2 (including
          without limitation use for commercial gain, for example by way of rental, licence, sale or providing services
          for consideration).
        </p>
        <p>
          6.4 Your access to or use of the Website and Content will not be construed as granting to You any licence or
          assignment of any Intellectual Property Rights in the Website or Content, other than to the extent expressly
          granted in this clause 6.
        </p>
        <p>
          6.5 You must inform Us promptly if You become aware of any infringement or potential infringement of any of
          the Intellectual Property Rights referred to in this clause 6.
        </p></govuk-accordion-item
      >

      <govuk-accordion-item [header]="'7. Privacy'"
        ><p>
          7.1
          <a href="/privacy-notice" title="Privacy Notice" class="govuk-link" rel="noreferrer noopener">
            Privacy Notice
          </a>
        </p></govuk-accordion-item
      >

      <govuk-accordion-item [header]="'8. Termination'">
        <p>
          8.1 We may terminate Your access to any part of the Website and/or any related service(s) and/or any right
          granted under clause 6 (Intellectual Property Rights) upon reasonable notice or, without notice, if You breach
          any of these Terms and Conditions.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'9. Modification to Terms and Conditions'">
        <p>
          9.1 We may change these Terms and Conditions at any time and will notify the changes by posting an updated
          version of the Terms and Conditions on the Website. We will not be required to obtain Your consent to any such
          change. You are responsible for regularly reviewing these Terms and Conditions. Continued use of the Website
          after any such changes will constitute Your consent to such changes.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'10. General'">
        <p>
          10.1 These Terms and Conditions will be governed by and construed in accordance with the laws of England and
          Wales. You and We will take reasonable steps to resolve any differences between us regarding these Terms and
          Conditions. However, if we are unable to do so, any dispute or legal proceeding in relation to the Website
          will be brought in the courts of England and Wales.
        </p>
        <p>
          10.2 The language of these Terms and Conditions is English. If these Terms and Conditions are for any reason
          translated into any language other than English the English text will prevail.
        </p>
        <p>
          10.3 If any provision or part of a provision of these Terms and Conditions is held by any competent authority
          to be invalid or unenforceable in whole or in part, such provision will (to the extent that it is invalid or
          unenforceable) be severable and all other provisions will remain in full force and effect.
        </p>
        <p>
          10.4 These Terms and Conditions comprise the entire agreement between You and Us in relation to Your access to
          and use of the Website. To the extent that there is any conflict or discrepancy between these Terms and
          Conditions and the Finance Act, the Administration Regulations or Eligibility Regulations then, to the extent
          of the conflict or discrepancy, the order of precedence shall be: (i) the Finance Act; (ii) the Administration
          Regulations; (iii) the Eligibility Regulations; and (iv) these Terms and Conditions.
        </p>
        <p>
          10.5 Nothing in these Terms and Conditions will prejudice or affect Our or DESNZ's exercise of Our or its
          functions, duties and powers conferred, arising or imposed under the CCA Legislation, the Environment Act 1995
          or any other Law whatsoever.
        </p>
        <p>
          10.6 Nothing in these Terms and Conditions will operate as a statutory approval, consent or licence from Us or
          DESNZ.
        </p>
        <p>
          10.7 A person who is not a party to these Terms and Conditions has no rights under the Contract (Rights of
          Third Parties) Act 1999 to enforce any term of this agreement.
        </p>
        <p>
          10.8 You may not transfer or in any other way make over to any third party the benefit of these Terms and
          Conditions either in whole or in part.
        </p>
      </govuk-accordion-item>
    </govuk-accordion>

    <p>&#xa0;</p>
    <br style="page-break-before: always; clear: both" />

    <h2 class="govuk-heading-m">Climate Change Agreements Register Administrative Rules</h2>
    <p>The Climate Change Agreements Register for the UK is administered and maintained by the Environment Agency</p>

    <govuk-accordion
      [openIndexes]="[
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
        30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
      ]"
    >
      <govuk-accordion-item [header]="'Part 1 - Introduction'">
        <p>
          1 This document is the Administrative Rules prepared by the Administrator under Regulation 4(5) of the
          Administration Regulations.
        </p>
        <p>2 The Administrative Rules comprise the following parts (<strong>Parts</strong>):</p>
        <p>Part 1 Introduction;</p>
        <p>Part 2 The general rules for use of the Register;</p>
        <p>Part 3 The access and use procedures for Applicants, Account Holders and their Representatives; and</p>
        <p>Part 4 Definitions and Interpretation.</p>
        <p>
          3 References to any of the Parts of the Administrative Rules are to those Parts as and to the extent from time
          to time modified by the Administrator.
        </p>
        <p>
          4 During the Enrolment process, an Applicant nominates individuals to act as its Representatives. The
          Representatives thereafter represent the Applicant or Account Holder, as applicable, and in this capacity may
          carry out certain administrative tasks in the Register at differing levels dependent on their authority.
        </p>
        <p>
          5 These Administrative Rules do not prescribe the process for entering into an Agreement. However, following
          initial dialogue with DESNZ and Enrolment of Representatives, for an Applicant (who is a prospective Sector
          Association or Operator), applications for an Agreement are made by or on behalf of an Applicant using the
          Register.
        </p>
        <p>
          6 In respect of successful applications, Accounts are opened in the Register and the Agreements are
          administered and maintained in the Register.
        </p>
        <p>
          7 In conjunction with the CCA Legislation and the Agreements, these Administrative Rules govern access to, and
          use of the Register, and operation of Accounts.
        </p>
        <p>
          8 An Applicant, Account Holder or Representative is bound by the Administrative Rules without explicitly being
          required to accept them, as the Administrative Rules are an administrative instrument. However, such persons
          are required to acknowledge that they have read and understood the Administrative Rules by clicking on the
          "accept" button when Enrolling. Whenever the Administrative Rules are updated such persons will be required to
          acknowledge that they have read and understood the updated version of the Administrative Rules.
        </p>
        <p>
          9 The Register is established for the purposes of the administration of Agreements and Accounts under the
          Scheme, including applications to enter into Agreements and to open Accounts in the Register, maintenance of
          records and electronic submission of those documents and information required by the Administrator to be in
          compliance with the Scheme. This will operate from 1 April 2013 to 30 June&#xa0; 2027 inclusive.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'Part 2 - The general rules for use of the Register'">
        <p>
          10 This Part 2 sets out the general rules for the use of the Register, including for the application by an
          Applicant for an Agreement and/or an Account and the subsequent operation and administration of that Agreement
          and/or Account.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'11. Relationship between the Administrator and an Account Holder or Applicant'">
        <p>11.1 The Administrative Rules govern the relationship between:</p>
        <p>(a) the Administrator; and</p>
        <p>(b) the Account Holder or Applicant, as applicable.</p>
        <p>
          Nothing in these Administrative Rules shall be construed as conferring a right, benefit, entitlement or
          privilege on any third party (other than the limited rights granted to Representatives herein).
        </p>
        <p>
          11.2 A Representative, once nominated, represents and acts on behalf of an Account Holder or Applicant.
          Applicants and Account Holders shall be bound by the acts and omissions of their Representatives, whether or
          not any act or omission is outside the authority (express or implied) of such Representatives.
        </p>
        <p>11.3 The Administrator may take all acts and omissions of a Representative:</p>
        <p>(a) in relation to a Applicant's application; and</p>
        <p>(b) in relation to an Account Holder's Account and/or Agreement;</p>
        <p>
          to have been legitimately taken on behalf of that Applicant or Account Holder (as applicable) and within its
          scope of authority (express or implied). The Administrator may rely on any such act or omission and need not
          verify or make any enquiry as to the authority of that Account Holder or Representative (as the case may be).
        </p>
        <p>
          11.4 Where the Administrative Rules impose an obligation on an Account Holder, such obligation may be carried
          out on its behalf by its Representative.
        </p>
        <p>
          11.5 In this Part 2, and in Part 3, where the context allows, references to an Applicant or an Account Holder
          include references to their Representatives and each of them appointed by, and acting on behalf of, the
          Applicant or the Account Holder.
        </p>
        <p>
          11.6 It shall be the responsibility of an Applicant or Account Holder (as the case may be) to ensure that all
          Representatives it appoints are competent to perform, and do perform, their obligations in accordance with the
          Administrative Rules. The Administrator shall have no duty or obligation to check that this is the case.
        </p>
        <p>
          11.7 Where a Representative ceases to be authorised by an Applicant or Account Holder, the Applicant or
          Account Holder must immediately suspend the user access for that Representative to the Register or applicable
          Account (for both Underlying and Umbrella Agreements) for which that Representative is responsible. If the
          Applicant or Account Holder is unable to do so for any reason it must immediately notify the Administrator who
          will suspend and deactivate the relevant user's access to the specified Account.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'12. The Register'">
        <p>12.1 The Register allows for:</p>
        <p>(a) Enrolment by Representatives;</p>
        <p>(b) application for Agreements and Accounts by Representatives on behalf of Applicants;</p>
        <p>
          (c) electronic communications between: (i) Applicants, Account Holders and their Representatives; and (ii) the
          Administrator;
        </p>
        <p>(d) access to and operation of Accounts by an Account Holder;</p>
        <p>
          (e) provision of data and information by an Applicant or an Account Holder to the Administrator: to
          demonstrate satisfactory progress toward meeting the Target as provided for in the Underlying Agreement; to
          enable processes for the Buy-Out Fee to be carried out; and to manage and maintain the details of Account
          Holders, Facilities and their Representatives; and
        </p>
        <p>
          (f) such other processes as are necessary or reasonably incidental to the requirements of the CCA Legislation
          and Agreements.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'13. Communication'">
        <p>
          13.1 All communications made via or relating to the Register shall be in English. However, such communication
          may be in Welsh in addition to English, if an Applicant or Account Holder has requested in writing to the
          Administrator for communications to and from it (and its Representatives) to be in Welsh.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'14. Availability of the Register'">
        <p>14.1 The availability of the Register is subject to:</p>
        <p>
          (a) planned downtime or maintenance (including upgrades and reconfigurations) of the Register, communicated in
          advance by the Administrator to Account Holders, Applicants and Representatives from time to time;
        </p>
        <p>(b) suspension, interruption, shutdown, delay and/or closure (in whole or part) for a Contingency; and</p>
        <p>
          (c) suspension, interruption, shutdown, delay and/or closure (in whole or part) where the Administrator
          believes that the security of the Register may be at risk.
        </p>
        <p>
          During the events or circumstances set out in Paragraphs 14.1(b) and (c) above the information contained in
          the Register may not be fully up to date.
        </p>
        <p>
          14.2 Without prejudice to Paragraph 14.1 above, the availability to an Applicant, Account Holder (and/or their
          Representatives) of the Register or the relevant Account is subject to:
        </p>
        <p>
          (a) the suspension or restriction of access to the Register of a Representative, an Applicant or an Account
          Holder;
        </p>
        <p>
          (b) the suspension or restriction of access to and operation of an Account by an Account Holder or a
          Representative, or suspension or restrictions of an Account altogether;
        </p>
        <p>(c) the suspension or restriction of use of the Register; and</p>
        <p>(d) the closure of the Account of an Account Holder.</p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'15. The Administrator'">
        <p>
          15.1 The Administrator may, under the Administrative Rules, do anything necessary for or reasonably incidental
          to the exercise of its powers and performance of its duties, responsibilities and functions set out in the CCA
          Legislation and the Agreements. However, the Administrative Rules do not grant any powers to the
          Administrator, or impose any duties, responsibilities or functions on the Administrator, which are not already
          granted or imposed under the CCA Legislation, the Agreements or the Environment Act 1995 or otherwise.
        </p>
        <p>
          15.2 The Administrator will grant access to the Register to those requiring or, where applicable, requesting
          it through initial dialogue with DESNZ. This will include the relevant Sector Associations and Operators, and
          their Representatives.
        </p>
        <p>
          15.3 The operation of the Register involves the possibility of error. Applicants and Account Holders shall
          abide by the provisions of the Administrative Rules, notwithstanding any such error.
        </p>
        <p>
          15.4 The Administrative Rules are rules for the operation of the Register and Accounts and are not intended to
          create any liability of the Administrator to an Applicant, Account Holder, Representative or any other person
          other than that arising under CCA Legislation and/or to the extent expressly provided for under these
          Administrative Rules.
        </p>
        <p>
          15.5 Without prejudice to Paragraph 14.1(b), the following shall apply in the circumstances of a Contingency:
        </p>
        <p>
          (a) the Administrator shall be relieved from performance of its duties, responsibilities and functions under
          the Administrative Rules for the duration of the Contingency;
        </p>
        <p>
          (b) the Administrator shall take reasonable steps to limit the duration and impact of the Contingency on the
          Register; and
        </p>
        <p>
          (c) upon the Contingency coming to an end, or ceasing to exist, the Administrator shall resume full
          performance of its duties, responsibilities and functions under the Administrative Rules.
        </p>
        <p>
          15.6 The Administrator may use information provided by an Applicant, Account Holder or Representative in the
          exercise of its powers and the discharge of its duties, responsibilities and functions under the Agreements or
          under any Law or Direction.
        </p>
        <p>
          15.7 Without prejudice to the Administrator's obligations under the Administration Regulations to take
          reasonable steps to check identities in relation to the formalities of approving an Applicant to enter into an
          Agreement and for the opening of an Account, the Administrator may (but shall not be obliged to) confirm the
          authenticity or validity of documents and/or information provided to it by, or on behalf of, an Applicant, an
          Account Holder or a Representative.
        </p>
        <p>
          15.8 The Administrator may act, or omit to act, under the Administrative Rules in order to comply with or to
          avoid the breach of any Law or Direction, and shall not be in breach of the Administrative Rules, or be liable
          to an Account Holder, Applicant, Representative or any other person in so doing.
        </p>
        <p>
          15.9 Without prejudice to the Administrator's rights, powers, privileges, duties or remedies under the CCA
          Legislation, the Agreements or the Administrative Rules, nothing in the Administrative Rules excludes or
          restricts or otherwise prejudices or affects any of the rights, powers, privileges, duties or remedies of:
        </p>
        <p>(a) the Environment Agency in any capacity other than Administrator; or</p>
        <p>(b) any other Relevant Authority.</p>
        <p>
          15.10 The Register will be supported by the Administrator (for example through provision of a Helpdesk) during
          Working Days only.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'16. Applicants and Account Holders'">
        <p>
          16.1 Nothing in the Administrative Rules grants any right to an Applicant or Account Holder which is not
          contained in, or reasonably incidental to the CCA Legislation and Agreements.
        </p>
        <p>
          16.2 An Applicant or Account Holder shall comply with, and shall ensure that its Representatives comply with,
          the Administrative Rules and the Website Terms and Conditions in respect of use of the Register, the operation
          of Accounts and use of the Website.
        </p>
        <p>
          16.3 An Applicant or Account Holder may only use the Register as permitted for the purposes of, or reasonably
          incidental to, compliance with the CCA Legislation, Agreements and the Administrative Rules.
        </p>
        <p>
          16.4 An Applicant or Account Holder shall not use the Register for any illegal activities or to commit, to
          attempt to commit, or in connection with the commission of, any criminal offence.
        </p>
        <p>16.5 An Applicant or Account Holder shall not use the Register in a manner which:</p>
        <p>(a) infringes the rights of the Administrator, any other Applicant or Account Holder or any other person;</p>
        <p>(b) restricts or inhibits the availability of the Register;</p>
        <p>(c) restricts or inhibits the operation of the Register; or</p>
        <p>
          (d) restricts or inhibits the use of the Register, or the operation of an Account, by any other Applicant,
          Account Holder or any third party (including the Administrator).
        </p>
        <p>
          16.6 An Applicant or Account Holder shall not, and shall ensure that its Representatives shall not, access or
          attempt to access any part of the Register (including the information relating to another Applicant or any
          Account of another Account Holder) to which it does not have express rights of access.
        </p>
        <p>
          16.7 Subject to the Administrator having taken reasonable steps to ensure the reliability and security of the
          Register, an Applicant or Account Holder is solely responsible for any damage to its computer system or
          settings or those of any of its Representatives, and for any loss of data that it or any of its
          Representatives may suffer, resulting from use of the Register.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'17. Responsibilities'">
        <p>
          17.1 Without prejudice to an Account Holder's responsibility to comply with and/or perform its obligations
          under the CCA Legislation and Agreements and without prejudice to an Applicant's or Account Holder's
          responsibilities as set out in Part 4,, Applicants and Account Holders are responsible for:
        </p>
        <p>
          (a) complying with any instruction given, or procedure required by or on behalf of the Administrator and shall
          bear the consequences of any failure to do so;
        </p>
        <p>
          (b) maintaining the security of Credentials received by it or issued by it or its Representatives and shall
          bear the consequences of any failure to do so;
        </p>
        <p>
          (c) obtaining all necessary consents, including licences, authorisations and exemptions required to use the
          Register, apply for Agreements and Accounts, operate Accounts and participate in Buy-Out Fee transactions (if
          any);
        </p>
        <p>(d) paying the Charges (either directly or where applicable via a Sector Association); and</p>
        <p>
          (e) ensuring details in the Register relating to the Facilities, Operators, Sector Association and all
          Personal Information (as appropriate) are accurate and are kept up to date; and
        </p>
        <p>
          (f) in relation to an Account Holder, ensuring that they are permitted by the relevant Agreement to change
          details in the Register.
        </p>
        <p>
          17.2 The Register provides a facility to submit information to and communicate with the Administrator. The
          Account Holder remains entirely responsible for complying with their Agreement in accordance with the CCA
          Legislation, including, but not limited to, the time limits set out in the Administration Regulations.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'18. Representatives'">
        <p>
          18.1 For the purpose of the Administrative Rules, an Applicant shall authorise each of its Representatives to:
        </p>
        <p>(a) access the Register on its behalf;</p>
        <p>
          (b) confirm that the applicant has read and understood the Administrative Rules and all modifications to the
          Administrative Rules;
        </p>
        <p>(c) Enrol the Applicant in the Register;</p>
        <p>(d) carry out the applicable processes for use of the Register;</p>
        <p>(e) in the case of a Sector Manager as Representative:</p>
        <p>
          (i) appoint new Representatives of all types other than the Responsible Person and issue to them Credentials;
        </p>
        <p>
          (ii) update in the Register its details and the details of other Representatives other than the Responsible
          Person;
        </p>
        <p>(iii) update in the Register the details of an Applicant where such details have been changed;</p>
        <p>(iv) view and edit the details of all its proposed Target Units and Facilities within the sector; and</p>
        <p>(v) view and edit the Personal information;</p>
        <p>where such details have changed, and in each case alternatively request the Administrator to do the same;</p>
        <p>(f) in the case of a Consultant Manager:</p>
        <p>
          (i) appoint new Representatives of all types other than the Responsible Person and Sector Manager and issue to
          them Credentials;
        </p>
        <p>
          (ii) update in the Register its details and the details of other Representatives other than the Responsible
          Person;
        </p>
        <p>(iii) update in the Register the details of an Applicant where such details have been changed;</p>
        <p>(iv) view and edit the details of all its proposed Target Units and Facilities within the sector; and</p>
        <p>(v) view and edit the Personal information;</p>
        <p>where such details have changed, and in each case alternatively request the Administrator to do the same;</p>
        <p>(g) in the case of a Sector User or Consultant User:</p>
        <p>(i) update in the Register its details;</p>
        <p>(ii) update in the Register the details of an Applicant where such details have been changed;</p>
        <p>(iii) view and edit the details of all its proposed Target Units and Facilities within the sector; and</p>
        <p>(iv) view and edit the Personal information;</p>
        <p>where such details have changed and in each case alternatively request the Administrator to do the same.</p>
        <p>
          18.2 For the purpose of the Administrative Rules, an Account Holder shall authorise each of its
          Representatives to do all of the things listed in Paragraph 18.1 in relation to it and its Account and in
          addition:
        </p>
        <p>(a) to submit information in relation to the progress made by the Operator towards meeting its Target;</p>
        <p>(b) to submit information enabling it to participate in and complete a Buy-Out Fee transaction; and</p>
        <p>
          (c) to submit any other information in the Register that it may be required to submit to the Administrator
          pursuant to its Agreement, in relation to which the Administrator requests or requires it to supply using the
          Register, either directly or in technical guidance on the use of the Register.
        </p>
        <p>
          18.3 In connection with its use of the Register, on an ongoing basis, and each time it accesses the Register
          via a Representative, the Applicant or Account Holder represents that:
        </p>
        <p>
          (a) all information submitted to the Administrator by it or on its behalf is true, accurate and not misleading
          nor a misrepresentation;
        </p>
        <p>
          (b) it has notified all its Representatives that Personal Information required by the Administrator (if any)
          has been passed to the Administrator and will be used in accordance with Paragraph 21 and 22;
        </p>
        <p>
          (c) it has the power and authority to enter into and perform its obligations under the Administrative Rules
          and has obtained all necessary approvals to do so; and
        </p>
        <p>
          (d) it has authorised its Representatives to confirm that they have read and understood the Administrative
          Rules and all modifications to them on its behalf.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'19. Statements of the Administrator'">
        <p>
          19.1 To the extent permissible having regard to its obligations, duties and responsibilities under the CCA
          Legislation and the Agreements, nothing in these Administrative Rules shall be construed as a confirmation
          from the Administrator to an Applicant, an Account Holder or any Representative:
        </p>
        <p>(a) as to the accuracy of any Third Party Information contained in, or accessed from, the Register;</p>
        <p>(b) as to the reliability of the Register;</p>
        <p>(c) that the Register or its Content will be free from errors; or</p>
        <p>
          (d) that the Register or its Content and the related server are free from computer viruses or other harmful
          applications.
        </p>
        <p>
          19.2 The Administrator may remove any material posted to the Register by other parties which is, or is in its
          reasonable opinion, defamatory, obscene, inaccurate, misleading, unlawful, pornographic or which the
          Administrator considers, or is notified that, in any way infringes another party's rights, including but not
          limited to intellectual property rights. The Administrator shall not be responsible or liable for the content
          of such material.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'20. Modifications'">
        <p>20.1 The Administrative Rules may only be modified in accordance with this Paragraph 20.</p>
        <p>20.2 The Administrator may modify the Administrative Rules at its sole discretion at any time.</p>
        <p>
          20.3 The Administrator shall not be required to obtain the consent of Applicants, Account Holders or
          Representatives or to inform any of them in advance of any modification to the Administrative Rules.
        </p>
        <p>
          20.4 Any modification to the Administrative Rules shall take effect at the time specified by the
          Administrator.
        </p>
        <p>
          20.5 Whilst the Administrator will take reasonable steps to bring modifications of the Administrative Rules to
          the attention of Applicants, Account Holders and Representatives it is the responsibility of each of them to
          check the Website to find out whether the Administrative Rules have been modified. In addition, it is the
          responsibility of Applicants and Account Holders to ensure that their Representatives have read and understood
          any modified Administrative Rules.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'21. Confidentiality'">
        <p>
          21.1 The Administrator shall, subject to Paragraph 21.2, treat as confidential, information relating to an
          Applicant, an Account Holder, or a Representative, including any Personal Information held in the Register and
          to which the Administrator has access or which is otherwise held by the Administrator, including Account Data
          and any document, electronic file or any other way of representing or recording information which contains or
          is derived or copied from such information.
        </p>
        <p>
          21.2 The Administrator may disclose information referred to in Paragraph 21.1 without giving notice to the
          Applicant, Account Holder or Representative in any of the following circumstances:
        </p>
        <p>
          (a) where the disclosure is required to be made (including to other parties in connection with Enrolment,
          application for Agreements or Accounts and identity checking formalities) for the purposes of providing access
          to, operating and maintaining the Register and acting as Administrator in accordance with the CCA Legislation,
          the Agreements and the Administrative Rules;
        </p>
        <p>
          (b) where the disclosure is required to be made in order to provide, operate and maintain security measures
          for the Register;
        </p>
        <p>(c) where the disclosure is required to be made to any Relevant Authority (in its capacity as such);</p>
        <p>
          (d) where the information is made available by the Administrator on the Website under or in connection with
          its obligations under the CCA Legislation;
        </p>
        <p>
          (e) where the disclosure is required to be made under and in accordance with any Law (including the Freedom of
          Information Legislation) or any Direction;
        </p>
        <p>
          (f) where the disclosure is required to be made to prevent or investigate fraud, money laundering or any other
          unlawful activity;
        </p>
        <p>
          (g) where the disclosure is required to be made in the course of legal proceedings or pursuant to any court
          order;
        </p>
        <p>
          (h) where the information is already in the public domain, except where that is due to a breach of
          confidentiality by any person; or
        </p>
        <p>
          (i) in order to publish on the Website and/or disclose to any Relevant Authority information comprising any or
          all of the following:
        </p>
        <p>
          (i) name and address for Facilities which are to be taken as being covered by an Agreement for the appropriate
          period as provided for in Paragraph 44 of Schedule 6 of the Finance Act, except where the same have been
          excepted from publication for reasons of security and following a request from an Operator (pursuant to the
          procedure and provisions set out in the Administration Regulations);
        </p>
        <p>
          (i) the list of Facilities that are the subject of a reduced rate certificate as provided for in Paragraph 44
          of Schedule 6 of the Finance Act and in respect of which variation certificates have been given since the last
          publication of the list; and
        </p>
        <p>
          (ii) a report, prepared at the end of every Target Period by the Administrator setting out energy and carbon
          efficiency improvements and emission reductions achieved under the Target Period as provided for in Regulation
          9(3) of the Administration Regulations.
        </p>
        <p>
          21.3 An Applicant and an Account Holder shall, and shall ensure that its Representatives shall, treat as
          confidential, information relating to the Administrator disclosed to them under or in connection with the
          Administrative Rules and/or the CCA Legislation and/or the Agreements. An Account Holder may only disclose
          that information without the consent of the Administrator in the circumstances listed in Paragraphs 21.2(a).
          21.2(g), 21.2(h) and 21.2(i).
        </p>
        <p>
          21.4 If the Administrative Rules cease to apply to an Applicant or an Account Holder, this Paragraph 21 will
          continue to apply to such Applicant or Account Holder from the date of such cessation until the date three (3)
          years after such cessation.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'22. Data Protection Act'">
        <p>
          22.1 The Administrator has a statutory entitlement to use Applicants' details and Account Data (including, if
          the Account Holder or Applicant is an individual, any personal information about such person) in accordance
          with the purposes for which such data has been obtained (which shall include the purposes set out in this
          Paragraph 22).
        </p>
        <p>
          22.2&#xa0; Account Data supplied to the Administrator may include personal information about Representatives.
          The Administrator shall process that personal information in connection with the CCA Legislation and the
          Agreements and in accordance with the Administrative Rules, Data Protection Act 2018 and any other Law.
          Applicants and Account Holders shall notify their Representatives that their personal information has been
          passed to the Administrator and will be used in accordance with the Administrative Rules.
        </p>
        <p>
          22.3The Administrator may use any personal information an Applicant, Account Holder or Representative, has
          supplied in the following ways:
        </p>
        <p>
          (a) for assessing and processing any application for Enrolment, for an Agreement or for an Account (which may
          include making searches with credit reference or fraud prevention agencies);
        </p>
        <p>(b) in order to provide, operate and maintain security measures for the Register;</p>
        <p>(c) in order to operate and maintain the Register in accordance with CCA Legislation and all Laws;</p>
        <p>
          (d) for the purpose of contacting an Applicant in connection with their Enrolment or application for an
          Agreement or Account, or an Account Holder in connection with an Account;
        </p>
        <p>
          (e) to enable the Administrator to carry out statistical analysis or research and development in pursuance of
          its obligations, responsibilities and functions under the CCA Legislation and the Environment Act 1995;
        </p>
        <p>(f) for the purpose of preventing or investigating fraud, money laundering or other unlawful activity;</p>
        <p>
          (g) to enable the Administrator to comply with any other duties or obligations placed on it under CCA
          Legislation and the Agreements;
        </p>
        <p>(h) by providing it to any Relevant Authority in accordance with CCA Legislation;</p>
        <p>(i) in order to comply with the Freedom of Information Legislation or any other Law; and</p>
        <p>
          (j) in order to publish on the Website and/or disclose to any Relevant Authority information comprising any or
          all of the following:
        </p>
        <p>
          (i) name and address for Facilities which are to be taken as being covered by an Agreement for the appropriate
          period as provided for in Paragraph 44 of Schedule 6 of the Finance Act, except where the same have been
          excepted from publication for reasons of security and following a request from an Operator (pursuant to the
          procedure and provisions set out in the Administration Regulations);
        </p>
        <p>
          (ii) the list of Facilities that are the subject of a reduced rate certificate as provided for in Paragraph 44
          of Schedule 6 of the Finance Act and in respect of which variation certificates have been given since the last
          publication of the list; and
        </p>
        <p>
          (iii) a report prepared at the end of every Target Period by the Administrator setting out energy and carbon
          efficiency improvements and emission reductions achieved under the Target Period as provided for in Regulation
          9(3) of the Administration Regulations.
        </p>
        <p>
          22.4 The Administrator shall only use the personal information supplied by an Applicant, an Account Holder or
          their Representatives in the manner described above. In particular, other than for the purposes set out in
          Paragraph 22.3, the Administrator shall not sell, rent, trade or give the personal information supplied by an
          Applicant, an Account Holder or their Representatives to others.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'23. Notification of errors'">
        <p>
          23.1 If an Applicant or Account Holder becomes aware of any error in the Content, such error should be
          notified to the Administrator using the Helpdesk within 20 working days.
        </p>
        <p>
          23.2 Where an Applicant or Account Holder has made an error, it shall correct the error as soon as possible
          where it has sufficient user privileges to do so and otherwise use the Register to notify the Administrator of
          the error.
        </p>
        <p>
          23.3 If the Administrator identifies an error then it may correct the error after consultation with the
          Applicant or Account Holder, as may be the case, but the consent of the Applicant or Account Holder is not
          required.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'24. System errors'">
        <p>
          24.1 The Administrator may rectify System Errors without the consent of the Account Holder or its
          Representatives.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'25. Intellectual Property Rights'">
        <p>
          25.1 All Intellectual Property Rights in the Register, the Content and in the layout of the Website are owned
          by the Administrator (or its licensors).
        </p>
        <p>
          25.2 In relation only to the parts of the Register to which an Applicant or Account Holder has access rights
          the Administrator grants an Applicant or Account Holder the right to view, download or print the Content for
          its own private use or for use internally within the Account Holder's or Applicant's business or subject to
          the conditions set out in Paragraph 25.3.
        </p>
        <p>
          25.3 Subject to Paragraph 25.2 an Account Holder or Applicant may give copies of the Content that it has the
          right to access to others provided that:
        </p>
        <p>(a) it makes no charge;</p>
        <p>(b) it in no way modifies or edits the Content; and</p>
        <p>
          (c) it attaches a copy of this Paragraph 25 and obtains a legally binding undertaking from the recipient to
          comply with it.
        </p>
        <p>
          25.4 An Applicant or Account Holder shall continue to be bound by this Paragraph 25 for so long as it uses the
          Content pursuant to Paragraphs 25.2 and 25.3.
        </p>
        <p>
          25.5 Unless otherwise decided by the Administrator, any authorised copying shall acknowledge the
          Administrator's ownership of the Content. One way of doing this is by adding the words "Copyright Environment
          Agency or Crown Copyright" to the information or copy.
        </p>
        <p>
          25.6 An Applicant or Account Holder shall not, and shall ensure that its Representatives shall not, use the
          Content in any way other than as set out in Paragraph 25.2 above (including use for commercial gain, for
          example by way of rental, licence, sale or providing services for consideration).
        </p>
        <p>
          25.7 An Applicant's or Account Holder's access to or use of the Register and any Content shall not be
          construed as granting to them (or their Representative) any licence or assignment of any Intellectual Property
          Rights other than to the extent expressly granted in this Paragraph 25.
        </p>
        <p>
          25.8 An Applicant or Account Holder shall inform the Administrator promptly if it becomes aware of any
          infringement or potential infringement of any of the Intellectual Property Rights referred to in this
          Paragraph 25.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'26. Limitation of Liabilities'">
        <p>
          26.1 Without prejudice to other provisions of the Administrative Rules, and subject to Paragraphs 26.2, 26.3
          and 29, the total liability of the Administrator (including in respect of any of its employees, officers and
          agents) to an Applicant or Account Holder (and their relevant Representatives, employees, officers or agents)
          shall not exceed five thousand pounds sterling (£5,000) in a Year. This single cap applies even where an
          Account Holder has more than one Account or where the Administrator acts in more than one capacity under the
          Administrative Rules. This cap shall apply to any Loss arising under or in connection with these
          Administrative Rules or the Register including, but not limited to, Loss resulting from:
        </p>
        <p>(a) breach of these Administrative Rules;</p>
        <p>(b) suspension, interruption, delay, shutdown or closure of any part of the Register;</p>
        <p>
          (c) error or inaccuracy in, unreliability of, incompleteness of, viruses or other harmful applications in, the
          Register or failure to keep the Register up to date;
        </p>
        <p>
          (d) unauthorised access to, interference with, or alteration of, any communications to the Register or
          unauthorised access to, or interference with or modification of information recorded on the Register
          (including information which relates to another Applicant or Account Holder);
        </p>
        <p>
          (e) the performance or non-performance by the Administrator of its duties, functions or responsibilities under
          the CCA Legislation, Agreements or the Environment Act 1995;
        </p>
        <p>
          (f) any representation, statement or tortious act or omission of the Administrator, including negligence
          arising under or in connection with these Administrative Rules;
        </p>
        <p>(g) breach (whether by negligence or otherwise) by the Administrator of the CCA Legislation; or</p>
        <p>(h) any other matter under or relating to these Administrative Rules or the Register.</p>
        <p>
          26.2 Save for liability of an Applicant or Account Holder under Paragraph 27.1, and subject to Paragraphs 26.3
          and 29, an Applicant's or Account Holder's liability to the Administrator (and any of its employees, officers
          or agents) shall not exceed an amount of five thousand pounds sterling (£5,000) in a Year. This cap shall
          apply to any Loss arising under or in connection with these Administrative Rules or the Register, including
          Loss resulting from:
        </p>
        <p>
          (a) any breach by the Applicant or Account Holder (or their Representatives) of these Administrative Rules;
        </p>
        <p>
          (b) the suspension, interruption, delay, shutdown or closure of any part of the Register connected with a
          deliberate act or omission of such Applicant or Account Holder (or their Representatives);
        </p>
        <p>
          (c) an error in the content or accuracy or completeness or incompleteness of the information held on the
          Register that has been created or provided by such Applicant or Account Holder (or their Representatives);
        </p>
        <p>
          (d) any representation, information or warranty (other than a fraudulent misrepresentation), statement of such
          Applicant or Account Holder or their Representatives arising under or in connection with these Administrative
          Rules;
        </p>
        <p>
          (e) the performance or non-performance by such Applicant or Account Holder of its or their obligations under
          these Administrative Rules; or
        </p>
        <p>(f) any other matter under or relating to these Administrative Rules or the Register.</p>
        <p>
          26.3 Subject to Paragraph 28, the Administrator shall not be liable to an Applicant or Account Holder, or
          Representatives for any Loss arising out of any of the following:
        </p>
        <p>(a) the inability of the Administrator to act during a Contingency;</p>
        <p>(b) an error committed by another Applicant or Account Holder or the rectification of such error;</p>
        <p>(c) the acts or omissions of any other person with rights to use the Register;</p>
        <p>(d) an act or omission of the Administrator in the circumstances set out in Paragraph 15.8;</p>
        <p>
          (e) the Administrator acting in accordance with a Direction (even if such Direction is subsequently found to
          have been given incorrectly);
        </p>
        <p>
          (f) the Administrator not being aware of receipt of cleared funds as payment for a Buy-Out Fee as a result of
          a failure by the Relevant Authority or any technical error;
        </p>
        <p>(g) a breach by an Applicant or an Account Holder, or Representatives of the Administrative Rules;</p>
        <p>(h) information created or provided by an Applicant or Account Holder;</p>
        <p>(i) any information published on the Website; and</p>
        <p>
          (j) any other circumstances, including under Paragraphs 16.7 and 19.1 in respect of which the Administrator is
          stated in these Administrative Rules not to be liable.
        </p>
        <p>
          (k) Any regulatory fines or penalties levied on an Applicant, Account Holder or Representative, including
          civil penalties under the CCA Legislation or any penalty levied in any other jurisdiction.
        </p>
        <p>
          26.4 Subject to Paragraphs 26.1, 26.2, 27 and 29 neither (i) the Administrator nor (ii) an Applicant or
          Account Holder shall be liable to the other or any other person in any circumstances whatsoever for any of the
          following:
        </p>
        <p>(a) indirect or incidental loss or damage;</p>
        <p>
          (b) economic loss, loss of actual or anticipated profits or savings, loss of sales or turnover, loss of
          production, loss of or damage to reputation, loss of goodwill, loss of business opportunity, loss of
          customers;
        </p>
        <p>(c) loss of security rights or interests; or</p>
        <p>(d) loss of, or loss of use of, or damage or corruption of software or data.</p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'27. Indemnity'">
        <p>
          27.1 Each Applicant and Account Holder shall indemnify the Administrator, its employees, officers and agents
          against any and all Loss which the Administrator may suffer or incur in connection with:
        </p>
        <p>
          (a) any claim, demand, action or proceeding made or brought by any third party (<strong>Claimant</strong>) on
          account of any and all Loss, (whether direct or otherwise) suffered or incurred or alleged to have been or to
          be going to be suffered or incurred, by the Claimant or any of the Claimant's officers, employees or agents,
          arising from or in connection with an act or omission of an Applicant or Account Holder, their
          Representatives, officers, employees or agents;
        </p>
        <p>
          (b) any misuse by an Applicant, Account Holder, their Representatives, officers, employees, or agents of the
          Register including:
        </p>
        <p>
          (i) by introducing viruses, trojans, worms, logic bombs or other material which is malicious or
          technologically harmful;
        </p>
        <p>
          (ii) by attempts to gain unauthorised access to the Register or an Account, the server on which the Register
          is stored or any server, computer or database connected to the Register; or
        </p>
        <p>(iii) by attacking the Register via a denial-of-service attack or a distributed denial-of service attack.</p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'28. Fairness'">
        <p>
          28.1 The limitations and exclusions of liability set out in these Administrative Rules are fair and reasonable
          and have been established by the Administrator to reflect the nature and structure of the Scheme, the role of
          the Administrator in the Scheme and in establishing and running the Register, the function of the Register as
          a vehicle for recording information and administering Agreements, and for enabling the progress of Buy-Out Fee
          transactions, which serve a regulatory not commercial purposes.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'29. Liability not excluded'">
        <p>
          29.1 Nothing in these Administrative Rules limits or excludes liability that cannot, under Law, be limited or
          excluded.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'30. Dispute resolution'">
        <p>
          30.1 The means of resolution of any dispute between any of the following, or one or more of the following:
        </p>
        <p>(a) an Applicant;</p>
        <p>(b) an Account Holder;</p>
        <p>(c) a Representative; and/or</p>
        <p>(d) any other person other than the Administrator or DESNZ;</p>
        <p>
          under or in connection with these Administrative Rules or the CCA Legislation shall be a matter for such
          persons to determine, and shall not concern or bind the Administrator.
        </p>
        <p>30.2 Subject to Paragraph 30.1, all Disputes between:</p>
        <p>(a) the Administrator; and</p>
        <p>(b) an Applicant, an Account Holder and/or their Representatives;</p>
        <p>
          (each a party to a Dispute (<strong>Party</strong>)) shall be resolved in accordance with the procedures set
          out in this Paragraph 30.
        </p>
        <p>
          30.3 In the event of a Dispute the Parties must first attempt to resolve the Dispute by amicable discussion.
          Should resolution not be possible by amicable discussion:
        </p>
        <p>
          (a) any Party may require, by notice to all other Parties, that the Dispute be referred to a senior executive
          of each Party, each having authority to resolve the Dispute; and
        </p>
        <p>
          (b) the senior executives shall use reasonable endeavours to meet and attempt, in good faith, to resolve the
          Dispute.
        </p>
        <p>30.4 If:</p>
        <p>
          (a) the senior executives do not meet within 20 Working Days of a notice from a Party under Paragraph 30.3(b);
          or
        </p>
        <p>
          (b) the meeting having been held, the Parties are unable to resolve the Dispute within a further 20 Working
          Days:
        </p>
        <p>
          any Party may, upon agreement of all other Parties (which agreement shall not be unreasonably withheld), refer
          the Dispute to mediation. If, using reasonable endeavours and attempting in good faith to agree, the Parties
          are unable to agree after 20 Working Days on the appointment of a mediator, any of them may apply to CEDR for
          it to appoint a mediator on their behalf.
        </p>
        <p>
          30.5 Subject to Paragraphs 30.4, 30.8 and 30.9, no Party may bring court proceedings in relation to any
          Dispute until it has attempted to settle the Dispute by mediation and either the mediation has terminated or
          the other Party has failed to participate in the mediation.
        </p>
        <p>
          30.6 Each Party must ensure that it is represented in the mediation by an individual with authority to settle
          the Dispute and to sign any settlement agreement that may be reached. The mediator shall, in consultation with
          the Parties, determine the timetable and procedure for mediation. Unless otherwise decided, and subject to
          this Paragraph 30, the mediation rules of CEDR in force at the commencement of the mediation will apply.
        </p>
        <p>
          30.7 The mediation will take place in England and the language of the mediation will be English. Any mediation
          agreement entered into shall be governed by, and construed and take effect in accordance with the law of
          England and Wales.
        </p>
        <p>
          30.8 The mediation shall be conducted on a without prejudice basis and in strict confidence. The mediator
          shall incur no legal liability to either Party in respect of the mediator's role in the mediation, except in
          the case of proven fraudulent conduct on the part of the mediator. If, within 30 Working Days of the reference
          to mediation, the mediation has not resulted in the settlement of the Dispute being reached, then the
          mediation procedure shall, unless otherwise decided, be terminated and the Parties shall be free to take
          whatever other steps they consider appropriate to have the Dispute finally determined by the courts.
        </p>
        <p>
          30.9 Nothing in the Administrative Rules prevents or restricts the Administrator, an Applicant or Account
          Holder from enforcing any obligation owed to it under or pursuant to the Administrative Rules or from applying
          to a court for judicial review or interlocutory and/or injunctive relief.
        </p>
        <p>30.10 For the avoidance of doubt, the existence of a Dispute does not:</p>
        <p>(a) relieve any Party from any obligations under the CCA Legislation or the Agreements; or</p>
        <p>
          (b) exclude or restrict or otherwise prejudice or affect any of the rights, powers, privileges, duties or
          remedies of the Environment Agency in any capacity other than Administrator.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'31. Governing law and jurisdiction'">
        <p>
          31.1 The Administrative Rules shall be governed by and construed in accordance with the laws of England and
          Wales.
        </p>
        <p>
          31.2 The courts of England and Wales shall, subject to Paragraph 30.5 (Dispute resolution), have exclusive
          jurisdiction to settle any Dispute that may arise out of or in connection with these Administrative Rules and
          the Administrator and each Applicant or Account Holder hereby irrevocably submit to the jurisdiction of those
          courts.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'32. Miscellaneous'">
        <p>
          32.1 The Agreements, Administrative Rules, Administration Regulations and the Charging Scheme constitute the
          entire understanding between an Account Holder and the Administrator as regards use of the secure parts of the
          Register and supersede all previous communications, representations and arrangements whether oral or written.
        </p>
        <p>
          32.2 If any provision of the Administrative Rules is held by any Relevant Authority to be invalid or
          unenforceable in whole or in part, such provision shall (to the extent that it is invalid or unenforceable) be
          deemed to be severable and the validity of the other provisions of the Administrative Rules and the remainder
          of the provision in question shall not be affected.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item
        [header]="'Part 3 - The access and use procedures for Applicants, Account Holders and their Representatives'"
      ></govuk-accordion-item>

      <govuk-accordion-item [header]="'33. Introduction'">
        <p>
          33.1 These procedures set out the requirements that must be followed by an Applicant and by an Account Holder
          when Enrolling in the Register, applying for an Agreement and to open and operate an Account.
        </p>
        <p>
          33.2 The procedures also set out the requirements that an Applicant or Account Holder must ensure are adhered
          to by their Representatives (who act on their behalf according to their assigned level of user access).
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'34. Registration process'">
        <p>
          34.1 The Administrator, pursuant to its obligations, responsibilities and functions under the CCA Legislation,
          has established processes in the Register to achieve, amongst other requirements, the Enrolment of
          Representatives on behalf of Applicants and Account Holders.
        </p>
        <p>
          34.2 During the Enrolment process (where an individual does not already have Credentials) Credentials will be
          issued by the Administrator.
        </p>
        <p>34.3 A Representative for an eligible Applicant must first access the Register and input its Credentials.</p>
        <p>
          34.4 If a Representative has misplaced or lost, or loses its entitlement to, its Credentials, it shall
          immediately notify the Administrator by contacting the Helpdesk.
        </p>
        <p>
          34.5 A Representative issued with Credentials must, when Enrolling in the Register, change the password in a
          manner which satisfies the security requirements indicated in the Register.
        </p>
        <p>
          34.6 An Applicant may nominate additional Representatives and request that the Administrator permit their
          access to the area of the Register containing the Applicant's information. The Administrator may, at its
          discretion, issue additional user names and passwords to such Representatives.
        </p>
        <p>
          34.7 Once Enrolled, a Representative may apply, on behalf of a prospective Sector Association Applicant for
          whom he is authorised, to enter into an Umbrella Agreement.
        </p>
        <p>
          34.8 Once an Umbrella Agreement has been entered into by the Sector Association, an Account shall be opened
          for the relevant Sector Association for that Umbrella Agreement, as provided for in these Administrative
          Rules.
        </p>
        <p>
          34.9 The relevant Sector Association Account Holder shall then apply, on behalf of the prospective Operator
          Applicant, for an Underlying Agreement. Once the Underlying Agreement is entered into, the Account for the
          relevant Account Holder for that Underlying Agreement shall be opened, as provided for in these Administrative
          Rules.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'35. Operating an Account'">
        <p>
          35.1 At all times when an Account is open in the name of an Account Holder, the Account Holder shall be
          represented by at least one Representative.
        </p>
        <p>
          35.2 Each Representative, acting on behalf of the Account Holder is entitled to carry out tasks on the
          Register for the Account Holder, including all dealings with an Account.
        </p>
        <p>
          35.3 The Administrator shall take reasonable steps to allow the Account Holder and its Representatives to
          access and use the Register and operate the Account Holder's Account(s).
        </p>
        <p>
          35.4 If, at any time, an Account Holder is no longer represented by at least one Representative, it shall
          immediately, and in any event within 20 Working Days, take all necessary steps to ensure that a replacement
          Representative is nominated and validly appointed.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'36. Dealings with an Account'">
        <p>36.1 The Account Holder shall be responsible for operating its Account.</p>
        <p>
          36.2 An Operator Account Holder (acting under the CCA Legislation) is represented by the Sector Association in
          relation to which the Operator's Account is held.
        </p>
        <p>
          36.3 The Administrator shall not be responsible for checking the accuracy of information, relating to an
          Account Holder or its Account, provided or submitted by or on behalf of the Account Holder except where
          required under the CCA Legislation or Agreement.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'37. Security'">
        <p>37.1 An Account Holder (and to the extent that it applies, an Applicant) shall:</p>
        <p>(a) ensure that only its Representatives use and operate its Account on its behalf;</p>
        <p>
          (b) ensure that no person (other than it or its Representatives) has access to Credentials or any other
          security information or login details, held by it and/or its Representatives to gain access to the Register or
          an Account;
        </p>
        <p>
          (c) notify the Administrator immediately upon becoming aware of any unauthorised use of its Credentials, or
          any other breach of security; and
        </p>
        <p>
          (d) exit the secure areas of the Register by logging out of the Register in accordance with the instructions
          on the Website at the end of each session.
        </p>
        <p>
          37.2 The Administrator shall not be responsible for making enquiries into or investigating the authenticity of
          access or use of the Register, or operation of an Account, made by or on behalf of an Applicant or Account
          Holder, using valid Credentials or other such security information.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'38. Suspension of operation of an Account'">
        <p>
          38.1 The Administrator may at any time, and without notice, prevent or suspend operation of an Account by an
          Account Holder:
        </p>
        <p>
          (a) where it has reason to believe that evidence of the Account Holder's identity may be incorrect or
          incomplete and suitable and up to date evidence of such identity has not been provided; and/or
        </p>
        <p>(b) the Administrator is required to do so by Law or Enforcement Agencies.</p>
        <p>
          38.2 The Administrator may at any time, and without notice, prevent or suspend operation of, an Account by a
          particular Representative of an Account Holder:
        </p>
        <p>
          (a) where it has reason to believe that evidence of that Representative's identity may be incorrect or
          incomplete and suitable and up to date evidence of such identity has not been provided;
        </p>
        <p>(b) the Administrator is required to do so by Law or Enforcement Agencies; and/or</p>
        <p>(c) that Representative ceases to be authorised by the Account Holder.</p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'39. Suspension or restriction of use of the Register'">
        <p>
          39.1 The Administrator may at any time suspend or restrict an Account Holder's, an Applicant's or a
          Representative's use of the Register if:
        </p>
        <p>(a) that Applicant;</p>
        <p>(b) that Account Holder; and/or</p>
        <p>(c) any Representatives of that Account Holder or that Applicant;</p>
        <p>
          is in breach of the CCA Legislation or an Agreement or in the Administrator's opinion, is using or intending
          to use the Register for or in connection with a criminal offence.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'40. Payment of Charges'">
        <p>
          40.1 Payment of Charges by an Account Holder under 17.1 of the Administrative Rules shall be in accordance
          with the following:
        </p>
        <p>(a) the amount of the Charges paid shall be as set out in the Charging Scheme;</p>
        <p>(b) the methods for payment of the Charges will be those set out in the Charging Scheme; and</p>
        <p>
          (c) for a Sector Association that has additional responsibilities for collecting Charges and remittance to the
          Administrator, these additional obligations will be set out in the Charging Scheme and Umbrella Agreement.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'41. Communications about use of the Register'">
        <p>
          41.1 Use of the Register is based on electronic, web-based communications between the Account Holder and/or
          its Representatives, the Administrator and other external systems and databases.
        </p>
        <p>
          41.2 Without prejudice to the requirements relating to the service of documents under the CCA Legislation and
          the Agreements the Account Holder shall, so far as possible:
        </p>
        <p>(a) use the Register for electronic communications via the Website;</p>
        <p>
          (b) ensure that all formal processes are conducted using the automated processes provided for in the Register;
        </p>
        <p>(c) use the Helpdesk to make any other communications that cannot otherwise be made; and</p>
        <p>(d) check regularly for email communications or Website notices from the Administrator.</p>
        <p>
          41.3 Without prejudice to the requirements relating to the service of documents under the Administration
          Regulations and the Agreements, the Administrator shall:
        </p>
        <p>
          (a) pursuant to the Administration Regulations, use the Register so far as possible to communicate with
          Account Holders; and
        </p>
        <p>
          (b) assume that any email communications have been successfully delivered unless it receives a notice of
          delivery failure (in which case, it may resend the email).
        </p>
        <p>
          41.4 The email address for the Helpdesk is
          <a class="govuk-link" href="mailto:cca-help@environment-agency.gov.uk">
            cca-help&#64;environment-agency.gov.uk </a
          >.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'42. Representatives'">
        <p>
          42.1 For the purposes of accessing and using the Register and any Account, an Applicant shall be represented
          by Representatives, who act on behalf of and are capable of binding, the Applicant and who shall be authorised
          to do all of the things listed in Paragraph 18.1 above.
        </p>
        <p>
          42.2 For the purposes of accessing and using the Register and any Account, an Account Holder shall be
          represented by Representatives, who act on behalf of and are capable of binding, the Account Holder and who
          shall be authorised to do all of the things listed in Paragraphs 18.1 and 18.2 above
        </p>
        <p>
          42.3 If, at any time, for reasons beyond its control, an Applicant or Account Holder is no longer represented
          by at least one individual Representative, the relevant Applicant or Account Holder shall immediately, and in
          any event within 20 Working Days, take all necessary steps to ensure that at least one individual
          Representative is appointed.
        </p>
        <p>
          42.4 Where an Applicant or Account Holder appoints an agent as Representative (including a Consultant Agent),
          nothing in the Administrative Rules is intended to confer any rights, benefits, entitlements or privileges on
          any such agent, including any right to enforce or take any action in respect of rights granted to Applicants
          or Account Holders under the Administrative Rules.
        </p>
        <p>
          42.5 An Applicant or Account Holder shall be solely responsible for the acts and omissions of a person which
          ceases to be a Representative of such Applicant or Account Holder.
        </p>
        <p>
          42.6 The Administrator may take all acts and omissions of a Sector Association or any of its Representatives,
          in relation to an Operator's Account, to have been legitimately taken on behalf of that Operator and within
          its scope of authority (express or implied) and may rely on any act or omission and need not verify or make
          any enquiry as to the authority of that Account Holder or Representative (as the case may be) in relation to
          any of its acts or omissions.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'43. Opening an Account'">
        <p>
          43.1 The Administrator shall open and activate an Account for an Account Holder when it is satisfied that:
        </p>
        <p>(a) at least one Representative has been nominated by the Account Holder and Enrolled;</p>
        <p>
          (b) the Administrator is satisfied as to the checks as to identity and eligibility it is required to carry
          out;
        </p>
        <p>(c) the Account Holder has entered into an Agreement with the Administrator; and</p>
        <p>
          (d) all other steps which the Administrator may take in order to check the identity of the Account Holder and
          the nominated Representatives have been taken.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'44. Mandatory closing of an Account'">
        <p>
          44.1 If the Administrator terminates or is obliged to terminate an Agreement pursuant to its terms and the CCA
          Legislation it shall also close the Account held by the relevant Account Holder and any Surplus held in the
          Account immediately before its closure must be cancelled by the Administrator prior to such closure.
        </p>
        <p>
          44.2 The Administrator may, but shall not be obliged to, close an Account until it is satisfied that the
          Account Holder and its Representatives have complied with all outstanding applicable requirements under the
          CCA Legislation.
        </p>
        1
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'45. Records in Accounts'">
        <p>
          45.1 An Account Holder shall be responsible for submitting all necessary information required of it pursuant
          to its Agreement, including:
        </p>
        <p>
          (a) information required to be supplied in response to a request for information pursuant to its Agreement;
        </p>
        <p>(b) to notify the Administrator of changes to Personal Information;</p>
        <p>(c) to notify the Administrator that a Facility is no longer eligible for inclusion in an Agreement;</p>
        <p>
          (d) to notify the Administrator of a structural change as provided for in the Agreement, which may give rise
          to a variation to the Target; and
        </p>
        <p>
          (e) to provide information in the timescales required in the CCA Legislation and the Agreement to demonstrate
          that the Operator has made satisfactory progress towards meeting its Target.
        </p>
        <p>
          45.2 An Account Holder shall be responsible for checking and keeping up to date all information in its Account
          including in relation to progress against Targets and any Surplus recorded in the Account.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'46. Change of Responsible Person'">
        <p>
          46.1 An Operator may at any time nominate a new Responsible Person in respect of an Underlying Agreement or an
          Umbrella Agreement.
        </p>
        <p>
          46.2 The nomination of the new Responsible Person in respect of an Account and Agreement will only have effect
          where the Administrator is satisfied that the new Responsible Person is legally authorised to take that role
          in relation to the Operator or Sector Association as set out in the Agreement.
        </p>
        <p>
          46.3 Where a new Responsible Person is nominated, Credentials will be issued to them by the Administrator once
          the relevant approval process has been carried out by the Administrator pursuant to the Agreement.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'47. Change of details of Representatives and Account Holder'">
        <p>47.1 A Representative shall, via the Register, notify the Administrator of a change of:</p>
        <p>(a) its details;</p>
        <p>(b) the details of other Representatives; or</p>
        <p>(c) the details of an Account Holder;</p>
        <p>in accordance with the time limits laid out in the Agreements.</p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'Part 4 - Definitions and Interpretation'"></govuk-accordion-item>

      <govuk-accordion-item [header]="'48. Interpretation'">
        <p>48.1 In&#xa0;<strong>the Administrative Rules</strong>, unless the context otherwise requires:</p>
        <p>(a) the words "other", "include" and "including" do not denote limitation in any way;</p>
        <p>(b) the singular includes the plural and vice versa;</p>
        <p>
          (c) where the Administrative Rules define a word or expression, related words or expressions have a consistent
          meaning;
        </p>
        <p>
          (d) any reference to any treaty, statute, statutory provision or law, or decision of the European Parliament
          or directive of the Council of the European Union (whether issued jointly with any other person or under any
          other name), or direction, notification orders, standard or document issued by a Relevant Authority, includes
          a reference to such statute, statutory provision, law, directive, decision, direction, notification order,
          standard or document as amended, extended, re-enacted, consolidated or replaced from time to time, and
          includes any order, regulation, instrument or other subordinate legislation made under any such instrument, as
          may be amended, replaced, extended or consolidated from time to time;
        </p>
        <p>(e) references to a person includes an individual or a body of persons corporate or unincorporate;</p>
        <p>(f) references to time are to the time prevailing in London, England;</p>
        <p>
          (g) the tables of contents, headings, sub-headings and descriptions of paragraphs and clauses after
          cross-references are for convenience only and do not affect the construction of the Administrative Rules;
        </p>
        <p>
          (h) the language of the Administrative Rules is English. If the Administrative Rules are for any reason
          translated into any language other than English, the English text shall prevail;
        </p>
        <p>
          (i) unless otherwise specified or the context requires otherwise, terms used within the Administrative Rules
          shall have the meaning given to them in the Administration Regulations, Schedule 6 of the Finance Act and the
          Agreements; and
        </p>
        <p>
          (j) if there is any inconsistency between the Administrative Rules and the Finance Act, the Administration
          Regulations, Eligibility Regulations and Agreements then the provisions of first the Finance Act, and then the
          Administration Regulations and Eligibility Regulations and then the Agreements shall prevail to the extent of
          the inconsistency.
        </p>
      </govuk-accordion-item>

      <govuk-accordion-item [header]="'49. Defined Terms'">
        <p>49.1 In the Administrative Rules the following definitions shall apply:</p>
        <p>
          <strong>Account</strong>&#xa0;means an account in the Register held by a Sector Association or an Operator;
        </p>
        <p>
          <strong>Account Data</strong>&#xa0;means information and data provided in connection with: (i) any application
          for an Agreement and for an Account by a prospective Sector Association or Operator; or (ii) the nomination of
          Representatives by or on behalf of an Applicant or Account Holder; or (iii) the maintenance and administration
          of an Agreement by or on behalf of an Account Holder, including information relating to a Sector Association
          or Operator or any of its Facilities or any of its Representatives;
        </p>
        <p>
          <strong>Account Holder</strong>&#xa0;means a Sector Association or Operator which holds an Account in its own
          name;
        </p>
        <p>
          <strong>Administration Regulations</strong>&#xa0;means the Climate Change Agreements (Administration)
          Regulations 2012 (as amended from time to time);
        </p>
        <p>
          <strong>Administrative Contact</strong>&#xa0;means one of the authorised Representatives appointed by the
          Sector Association or Operator who is named as the administrative contact in the Agreement;
        </p>
        <p>
          <strong>Administrative Rules</strong>&#xa0;means the rules established by the Administrator in relation to the
          operation of the Register prepared pursuant to Regulation 4(5) of the Administration Regulations;
        </p>
        <p>
          <strong>Administrator</strong>&#xa0;means the Environment Agency in its capacity as administrator of the
          Register pursuant to Article 3 and 4(1) of the Administration Regulations;
        </p>
        <p><strong>Agreement</strong>&#xa0;means either an Umbrella Agreement or an Underlying Agreement;</p>
        <p>
          <strong>Applicant</strong>&#xa0;means a Sector Association or Operator which wishes to enter into an Agreement
          with the Administrator (and in relation to which DESNZ has passed information and contact details to the
          Administrator) or to open an Account in its own name, and which is applying for an Agreement or Account (as
          the case may be);
        </p>
        <p>
          <strong>Base Year</strong>&#xa0;means the base year set out in the Agreement by reference to which the
          percentage improvement in energy efficiency or carbon efficiency applicable to the target unit is measured;
        </p>
        <p>
          <strong>Buy-Out Fee</strong>&#xa0;means a fee calculated and payable in accordance with the Agreement to
          demonstrate satisfactory progress towards meeting a Target where the Target itself has not been met;
        </p>
        <p>
          <strong>CCA Legislation</strong>&#xa0;means the Finance Act, the Administration Regulations and the
          Eligibility Regulations together;
        </p>
        <p>
          <strong>CEDR</strong>&#xa0;means the Centre for Effective Dispute Resolution, UK Company number 2422813, whose
          website is at www.cedr.com;
        </p>
        <p><strong>Charges</strong>&#xa0;means the charges due to the Administrator under its Charging Scheme;</p>
        <p>
          <strong>Charging Scheme</strong>&#xa0;means the document issued by the Administrator entitled "Climate Change
          Agreements Charging Scheme" made pursuant to Paragraph 52C of Schedule 6 of the Finance Act;
        </p>
        <p><strong>Claimant</strong>&#xa0;has the meaning given in Paragraph 27.1;</p>
        <p>
          <strong>Consultant Agent</strong>&#xa0;means a consultant engaged by a Sector Association to administer
          actions in the Registry and in some or all of the Accounts which the Sector Association holds or for whom it
          is a Representative;
        </p>
        <p>
          <strong>Consultant Manager</strong>&#xa0;means an individual Representative authorised to act through a
          Consultant Agent who has enhanced user access in the Register;
        </p>
        <p>
          <strong>Consultant User</strong>&#xa0;means an individual Representative authorised to act through a
          Consultant Agent who has limited user access in the Register;
        </p>
        <p>
          <strong>Content</strong>&#xa0;means the data, text, images, material and information on the Register or
          available through the Register;
        </p>
        <p>
          <strong>Contingency</strong>&#xa0;means any event or circumstance or combination of events or circumstances
          beyond the reasonable control of the Administrator which prevents, hinders or delays the Administrator from
          performing any of its duties, functions or responsibilities under the Administrative Rules including:
        </p>
        <p>(a) an act of God;</p>
        <p>
          (b) riots, war or armed conflict declared or undeclared, threat of war, act of public enemy, acts of
          terrorism, blockade, revolution, insurrection, civil commotion, public demonstration, sabotage or act of
          vandalism;
        </p>
        <p>(c) fire, storm, flood, earthquake or other natural disaster;</p>
        <p>(d) strike, lockout or other industrial disturbance or dispute;</p>
        <p>
          (e) internet outages, communications outages, failures in communications equipment, power failures or
          fluctuations; and
        </p>
        <p>
          (f)&#xa0; faults or failures of: (i) providers of networks, services, equipment or software; (ii) providers of
          telecommunications services or software services; or (iii) other third party services to the Administrator;
        </p>
        <p><strong>Credentials</strong>&#xa0;means:</p>
        <p>
          (a) in relation to Enrolment, the single use user name and password generated by the Administrator or the
          Sector Manager or Consultant Manager as may be the case, for the purposes of Enrolment together with the
          user's email address; and
        </p>
        <p>
          (b) in relation to subsequent access to the Register, the user name, password followed by a 2 factor
          authentication is required for the user to access the Register together with the user's email address;
        </p>
        <p>
          <strong>DESNZ</strong>&#xa0;means the Secretary of State for Energy &amp; Climate Change acting through the
          Department for Energy Security and Net Zero in carrying out his responsibilities in relation to the Scheme
          under the CCA Legislation and Agreements;
        </p>
        <p>
          <strong>Direction</strong>&#xa0;means any applicable present or future directive, instruction, direction or
          rule (as modified, extended or replaced) of any Relevant Authority acting legitimately under Law;
        </p>
        <p>
          <strong>Dispute</strong>&#xa0;means a dispute between an Account Holder and the Administrator under the
          Administrative Rules;
        </p>
        <p>
          <strong>Eligibility Regulations</strong>&#xa0;means The Climate Change Agreements (Eligible Facilities)
          Regulations 2012 (as amended from time to time);
        </p>
        <p>
          <strong>Emissions</strong>&#xa0;means the total emissions in tCO2 (tonnes of carbon dioxide) for a Target
          Period;
        </p>
        <p>
          <strong>Enforcement Agencies</strong>&#xa0;means any governmental or other agency that has investigative or
          enforcement powers in relation to criminal activity, including but not limited to: the National Crime Agency;
          His Majesty's Revenue and Customs; His Majesty's Treasury, s and the Financial Conduct Authority;
        </p>
        <p>
          <strong>Enrolment</strong>&#xa0;means the process by which an individual user first gains authorised access to
          the Register;
        </p>
        <p>
          <strong>Facility</strong>&#xa0;means a facility within the meaning of paragraph 50(2) to (6) of Schedule 6 to
          the Finance Act;
        </p>
        <p><strong>Finance Act</strong>&#xa0;means the Finance Act 2000;</p>
        <p>
          <strong>Freedom of Information Legislation</strong>&#xa0;means the Freedom of Information Act 2000 and the
          Environmental Information Regulations 2004;
        </p>
        <p>
          <strong>Helpdesk</strong>&#xa0;means the telephone and e-mail help desk established by the Administrator to
          answer queries of Applicants and Account Holders which cannot be addressed via the Register;
        </p>
        <p>
          <strong>Intellectual Property Rights</strong>&#xa0;means all rights in inventions, patents, trademarks and
          service marks, logos, design rights, rights in trade names and business names, copyrights (including, for the
          avoidance of doubt, rights in computer software), database rights and semiconductor topography rights and all
          other rights in the nature of intellectual property rights (in each case whether registered or unregistered
          and including all applications for the same) anywhere in the world;
        </p>
        <p>
          <strong>Law</strong>&#xa0;means any applicable statute, ordinance or other law, regulation, by-law, order,
          decree, statutory instrument or other form of delegated legislation, any applicable treaty or international
          convention or regulation (and shall include the CCA Legislation);
        </p>
        <p>
          <strong>Loss</strong>&#xa0;means any loss, claim, costs (including professional costs), liability, damage or
          expense howsoever suffered or incurred;
        </p>
        <p><strong>Operator</strong>&#xa0;means a party to Underlying Agreement other than the Administrator;</p>
        <p><strong>Party</strong>&#xa0;has the meaning given in Paragraph 30.2;</p>
        <p><strong>Personal Information</strong>&#xa0;means:</p>
        <p>(a) the address of the registered office of the Sector Association or Operator;</p>
        <p>(b) the name, address and email address of:</p>
        <p>
          (i) in the case of a Sector Association, a person who can be contacted in respect of the Sector Association;
        </p>
        <p>(ii) in the case of an Operator, the Responsible Person; and</p>
        <p>
          (c) the name, address and email address of a person who can be contacted in respect of a Facility covered by
          an Agreement;
        </p>
        <p>
          <strong>Register</strong>&#xa0;means the secure electronic system established by DESNZ under Regulation 4(1)
          of the Administration Regulations and operated by the Administrator (or on its behalf);
        </p>
        <p>
          <strong>Relevant Authority</strong>&#xa0;means the Environment Agency, the Commissioners for His Majesty's
          Revenue and Customs the government of the United Kingdom or any ministry, department or political subdivision
          thereof, any court or tribunal or any other governmental entity, instrumentality, agency, authority, committee
          or commission (in each case, of any tier) under the direct or indirect control of the government of the United
          Kingdom or any independent regulatory authority relating thereto;
        </p>
        <p>
          <strong>Representative:</strong>
        </p>
        <p>
          (a) in relation to an Account Holder, means an individual authorised by an Account Holder who Enrols in the
          Register and has access to, operates and carries out administrative tasks in the Account or in relation to the
          Agreement on behalf of an Account Holder, which term includes, the Sector Manager (who is usually the
          Administrative Contact), the Sector Users (which may include the Responsible Person) and any Consultant Agent
          (including Consultant Manager and Consultant User);
        </p>
        <p>
          (b) in the case of the Operator as Account Holder, also includes the relevant Sector Association and any of
          that Sector Association's own Representatives so authorised;
        </p>
        <p>
          (c) in relation to an Applicant, means an individual authorised by an Applicant who Enrols in the Register and
          may apply for an Agreement and an Account on behalf of the Applicant, which term includes the Sector Manager
          (who is usually the proposed Administrative Contact), the Sector Users (which may include the proposed
          Responsible Person) and any Consultant Agent (including a Consultant Manager and Consultant User) so
          authorised; and
        </p>
        <p>
          (d) in the case of an Applicant for an Underlying Agreement, also includes the relevant Sector Association and
          any of that Sector Association's own Representatives so authorised;
        </p>
        <p>
          <strong>Responsible Person</strong>&#xa0;means the individual who is legally authorised to enter in the
          Agreement on behalf of the Sector Association or the Operator as the case may be, and to accept service of
          Notices on behalf of the Sector Association or Account Holder and to agree any amendments to an Underlying
          Agreement;
        </p>
        <p>
          <strong>Scheme</strong>&#xa0;means the Climate Change Agreements scheme established under the CCA Legislation;
        </p>
        <p>
          <strong>Sector Association</strong>&#xa0;means a party to an Umbrella Agreement other than the Administrator;
        </p>
        <p>
          <strong>Sector Commitment</strong>&#xa0;means a commitment notified to the Administrator by DESNZ, which is
          applicable to the Facilities to which an Umbrella Agreement applies and recorded in the Umbrella Agreement;
        </p>
        <p>
          <strong>Sector Manager</strong>&#xa0;means an individual Representative authorised to act on behalf of a
          Sector Association who has enhanced user access in the Register;
        </p>
        <p>
          <strong>Sector User</strong>&#xa0;means an individual Representative authorised to act on behalf of a Sector
          Association who has limited user access in the Register;
        </p>
        <p>
          <strong>Surplus</strong>&#xa0;means the amount by which the emissions have fallen below the Target for any
          Target Period;
        </p>
        <p>
          <strong>Suspend</strong>&#xa0;means to prevent or restrict the operation of an Account by an Account Holder
          and/or a Representative;
        </p>
        <p>
          <strong>System Error</strong>&#xa0;means an error and/or omission arising out of a design flaw, malfunction or
          other error that causes or may cause a failure of the Register to operate in accordance with its
          specifications;
        </p>
        <p>
          <strong>Target</strong>&#xa0;means a target for percentage improvement in energy or carbon efficiency from the
          Base Year applicable to a Target Unit set out in an Underlying Agreement, as varied from time to time;
        </p>
        <p>
          <strong>Target Period</strong>&#xa0;means any of the periods that are target periods under the Administration
          Regulations;
        </p>
        <p>
          <strong>Target Unit</strong>&#xa0;means the Facility or group of Facilities to which an Underlying Agreement
          applies;
        </p>
        <p>
          <strong>Third Party Information</strong>&#xa0;means all Content of an Applicant, Account Holder or
          Representative, including:
        </p>
        <p>
          (a) documents and information submitted to the Register by or on behalf of an Applicant or Account Holder; and
        </p>
        <p>(b) information or documents provided by, or relating to, such a party:</p>
        <p>(i) used by the Administrator to generate additional Content; or</p>
        <p>(ii) kept and maintained by the Administrator as Content;</p>
        <p>
          under the CCA Legislation and in each case including but not limited to the following which the Administrator
          is required under the Administration Regulations, subject to certain exceptions as to security, to publish and
          will publish on the Website:
        </p>
        <p>
          (a) the list of names and addresses of Facilities which are to be taken as being covered by an Agreement for
          the appropriate period as provided for in Paragraph 44 of Schedule 6 of the Finance Act;
        </p>
        <p>
          (b) the list published monthly of Facilities being the subject of a reduced rate certificate as provided for
          in Paragraph 44 of Schedule 6 of the Finance Act and in respect of which variation certificates have been
          given since the last publication of the list; and
        </p>
        <p>
          (c) the report prepared by the Administrator setting out energy efficiency improvements and emission
          reductions achieved under the Target Period as provided for in Regulation 9(3) of the Administration
          Regulations;
        </p>
        <p>
          but excluding such Content provided by, or relating to, such a party as the Administrator publishes or
          otherwise makes available on the Website which Content the Administrator is under a duty to verify under the
          Finance Act and the Administration Regulations;
        </p>
        <p>
          <strong>Underlying Agreement</strong>&#xa0;means an agreement that is an underlying agreement as provided for
          in the Administration Regulations;
        </p>
        <p>
          <strong>Umbrella Agreement</strong>&#xa0;means an agreement that is an umbrella agreement as provided for in
          the Administration Regulations;
        </p>
        <p>
          <strong>Variation</strong>&#xa0;means a variation under an Underlying Agreement or an Umbrella Agreement and
          pursuant to the Administration Regulations;
        </p>
        <p>
          <strong>Web page</strong>&#xa0;means an individual page (whether static or dynamically generated) available as
          part of the Website;
        </p>
        <p>
          <strong>Website</strong>&#xa0;means the website at https://
          manage-your-climate-change-agreements.service.gov.uk which hosts the Register and which is the online
          interface via which:
        </p>
        <p>(a) Applicants, Account Holders and Representatives may access the Register; and</p>
        <p>
          (b) members of the public can access certain public information available in relation to the operation of
          Account Holders' Agreements;
        </p>
        <p>
          <strong>Website Terms and Conditions</strong>&#xa0;means the document entitled "CCA Website Terms and
          Conditions" governing the use of the Website;
        </p>
        <p><strong>Working Day</strong>&#xa0;means any day other than:</p>
        <p>(a) a Saturday, Sunday, Good Friday or Christmas Day; or</p>
        <p>
          (b) bank holidays under the Banking and Financial Dealings Act 1971 (including those bank holidays in part
          only of the United Kingdom);
        </p>
        <p>
          <strong>Year</strong>&#xa0;means 1 April in a calendar year to 31 March in the next calendar year, inclusive
          of those dates.
        </p>
      </govuk-accordion-item>
    </govuk-accordion>
  </div>
}

<form (ngSubmit)="submitTerms()" [formGroup]="form">
  <div formControlName="terms" govuk-checkboxes>
    <ng-container govukLegend>
      <span class="govuk-visually-hidden">Accept terms and conditions</span>
    </ng-container>

    <govuk-checkbox [value]="true" label="I have read, understood and accept the terms and conditions"></govuk-checkbox>
  </div>

  <button netzPendingButton govukButton type="submit">Accept and continue</button>
</form>
