@if (showCookiesBanner() === true) {
  <cca-cookies-container></cca-cookies-container>
}

<govuk-header title="Manage your Climate Change Agreement" [showLogo]="showLogo">
  <govuk-skip-link />
  <govuk-header-actions-list>
    @if (isLoggedIn()) {
      <a class="govuk-header__link" routerLink="." (click)="authService.logout()"> Sign out </a>
    }
  </govuk-header-actions-list>

  @if (isAuthorized()) {
    <govuk-header-nav-list identifier="navigation" ariaLabel="Primary navigation">
      <a govukLink="header" [routerLink]="['/dashboard']"> Tasks </a>
      <a govukLink="header" [routerLink]="['/target-unit-accounts']"> Target unit accounts </a>

      @if (showSectors()) {
        <a govukLink="header" [routerLink]="['/sectors']"> Manage sectors </a>
      }

      @if (showRegulators()) {
        <a govukLink="header" [routerLink]="['/user/regulators']"> Regulator users </a>
      }
    </govuk-header-nav-list>
  }
</govuk-header>

<section class="govuk-width-container">
  <cca-phase-bar [isUserLoggedIn]="isLoggedIn()" />
  <cca-workflow-task-header />
  <netz-breadcrumbs />
  <netz-back-link />
  <main class="govuk-main-wrapper" id="main-content" role="main" tabindex="0">
    <router-outlet></router-outlet>
  </main>
</section>

<govuk-footer>
  <govuk-footer-meta-info>
    <a govukLink="meta" routerLink="/privacy-notice"> Privacy Notice </a>
    <a govukLink="meta" routerLink="/contact-us"> Contact us </a>
    <a govukLink="meta" routerLink="/accessibility"> Accessibility Statement </a>
    <a govukLink="meta" routerLink="/legislation"> UK ETS legislation </a>
  </govuk-footer-meta-info>
</govuk-footer>

<cca-timeout-banner />
